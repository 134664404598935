<template>
    <error-toast :errorMessage="errorMessage" @resetErroMessage="errorMessage = null">
        <template v-slot:error-message>
            <span class="pl-2 text-md">{{ errorMessage }}</span>
        </template>
    </error-toast>
    <BackArrow
        v-if="vendor"
        :pageTitles="{ heading: vendor.vendor_details.name, content: 'BACK TO Vendors' }"
    />
    <main v-if="vendor" class="bg-white mx-32 my-8 pt-10 pb-20 px-10 rounded-lg shadow text-sm">
        <section class="flex">
            <div class="w-36 mr-12 pt-4">
                <div
                    class="w-full h-36 bg-lightGreyBg rounded-full flex items-center justify-center"
                >
                    <img
                        :src="'http://procurement-api.suburbanfiberco.com/' + vendor.vendor_logo"
                        :alt="vendor.vendor_details.name"
                        class="h-full w-full rounded-full shadow"
                    />
                </div>
            </div>
            <div class="w-3/4">
                <h1 class="text-4xl font-bold my-5">{{ vendor.vendor_details.name }}</h1>
                <div>
                    <router-link
                        :to="{ name: 'newVendor', query: { vendorId: vendor.id } }"
                        class="rounded bg-primaryColor text-white py-2 px-5 text-center capitalize"
                        >edit vendor</router-link
                    >
                    <span
                        class="rounded bg-pillRejectedStatus text-white py-2 px-4 ml-5 text-center capitalize cursor-pointer"
                        @click="deleteVendor"
                    >
                        delete vendor
                    </span>
                </div>
            </div>
        </section>
        <hr class="my-7" />
        <section class="ml-48 text-lg">
            <div>
                <h3 class="font-bold capitalize leading-loose tracking-wide">status</h3>
                <span
                    v-if="vendor.vendor_details.is_banned === 0"
                    class="text-xs text-white bg-pillApprovedStatus rounded-full py-1 px-5"
                    >active</span
                >
            </div>
            <div v-if="vendor.website" class="my-7">
                <h3 class="font-bold capitalize leading-loose tracking-wide">Website</h3>
                <span>{{ vendor.website }}</span>
            </div>
            <div class="my-7">
                <h3 class="font-bold capitalize leading-loose tracking-wide">address</h3>
                <span>{{ vendor.address }}</span>
            </div>
            <div class="my-7">
                <h3 class="font-bold capitalize leading-loose tracking-wide">phone</h3>
                <span>{{ vendor.phone }}</span>
            </div>
            <div class="my-7">
                <h3 class="font-bold capitalize leading-loose tracking-wide">email</h3>
                <span>{{ vendor.vendor_details.email }}</span>
            </div>
            <div class="my-7">
                <h3 class="font-bold capitalize leading-loose tracking-wide">purchases</h3>
                <span>{{ vendor.purchases }}</span>
            </div>
        </section>
    </main>
    <LoadingSpinner v-else />
</template>

<script>
import Request from '@/services/requestHelper'
import BackArrow from '@/components/ui/BackArrow.vue'
import errorFunc from '@/util/error'

export default {
    name: 'vendorView',
    props: {
        vendorId: String,
    },
    components: { BackArrow },
    async mounted() {
        try {
            const { data } = await Request.getRequest(`vendor/${this.vendorId}`)

            this.vendor = data.data
        } catch (error) {
            errorFunc(error.response, this.$router)
            this.errorMessage = error.response.data.message
        }
    },
    data() {
        return {
            vendor: null,
            errorMessage: null,
        }
    },
    methods: {
        async deleteVendor() {
            try {
                const { status } = await Request.postRequest(`vendor/delete/${this.vendorId}`)

                if (status >= 200 && status < 300) {
                    this.$router.push({ name: 'vendors' })
                }
            } catch (error) {
                errorFunc(error.response, this.$router)
                this.errorMessage = error.response.data.message
            }
        },
    },
}
</script>
